import React from "react";
import Particles from "react-particles-js";
import Landing1 from "../../assets/img/landing/landing1.jpg";
import Landing2 from "../../assets/img/landing/landing2.jpg";
import Landing3 from "../../assets/img/landing/landing3.jpg";
import { Carousel } from "antd";
import TrackVisibility from "react-on-screen";
import "./Home.scss";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { determineOffSet } from "../../helpers/offset";

export default function Home({ content }) {
  return (
    <div id="landing">
      <TrackVisibility style={{ width: "100%" }} partialVisibility>
        {({ isVisible }) => isVisible && <Particles id="particles" />}
      </TrackVisibility>
      <div id="text">
        <div className="wrapper">
          <div className="landing-text">
            <div className="text-header">YL Switchgear Sdn. Bhd.</div>
            <div className="text-desc">
              <p>
                We are a company specialized in designing, customizing,
                manufacturing and supplying electrical low voltage (LV)
                switchboards with more than 10 years of experience.
              </p>
              <p>
                Our production factory is well equipped with modern facilities,
                CNC equipment, highly trained engineers and technicians.
              </p>
            </div>

            <div className="contact">
              <AnchorLink href="#contact" offset={() => determineOffSet()}>
                <button>CONTACT US&nbsp;&nbsp;&nbsp;&rarr;</button>
              </AnchorLink>
            </div>
          </div>

          <div className="landing-img">
            <Carousel autoplay effect="fade">
              <div>
                <img src={Landing1} alt="landing1" />
              </div>
              <div>
                <img src={Landing2} alt="landing2" />
              </div>
              <div>
                <img src={Landing3} alt="landing3" />
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}
