import React, { useState, useEffect } from "react";
import ReactMapboxGl, { Marker } from "react-mapbox-gl";
import { Collapse } from "antd";
import { projects as data } from "./../../assets/data/projects";
import project from "../../assets/svg/products/projects.svg";
import { Fade } from "react-reveal";
import "./Projects.scss";

const { Panel } = Collapse;

const Map = ReactMapboxGl({
  accessToken:
    "pk.eyJ1Ijoicm90b2dpc3RpY3MiLCJhIjoiY2p6YmExZmphMDA4ZDNpbzRwbGN0aDdndyJ9.NQxdA0NKAt_jkeMOowMNSQ",
  interactive: false,
});

export default function Projects({ content }) {
  const [filteredCoordinates, setActiveCoordinates] = useState(null);

  const setCoordinates = (projects = null) => {
    // Run through each project to create an array of coordinate to render on map
    const projectList = [];
    if (projects) {
      projects.forEach((project) => {
        if (project.location.coordinates.length > 0) {
          project.location.coordinates.forEach((coordinate) => {
            projectList.push({
              name: project.name,
              type: determineTypeStyle(project.type),
              lat: coordinate.lat,
              lng: coordinate.lng,
            });
          });
        }
      });
    } else {
      // Default data
      data.forEach((project) => {
        if (project.location.coordinates.length > 0) {
          project.location.coordinates.forEach((coordinate) => {
            projectList.push({
              name: project.name,
              type: determineTypeStyle(project.type),
              lat: coordinate.lat,
              lng: coordinate.lng,
            });
          });
        }
      });
    }
    setActiveCoordinates(projectList);
  };

  const determineTypeStyle = (type) => {
    switch (type) {
      case "Government Project":
        return 0;
      case "Factory / Plant / Expansion":
        return 1;
      case "MCC Control Panel":
        return 2;
      case "High Rise":
        return 3;
      case "Fast Pace":
        return 4;
      default:
        break;
    }
  };

  const displayActiveCoordinate = (index) => {
    // Sets coordinates of selected project when Panel is clicked
    if (index) {
      const selectedProject = data[index];
      setCoordinates([selectedProject]);
    } else {
      setCoordinates();
    }
  };

  const filterByType = () => {
    const types = [];
    data.forEach((project) => {
      const { type } = project;
      if (type && typeof type === "object") {
        type.forEach((type) => {
          if (!types.includes(type)) {
            types.push(type);
          }
        });
      } else if (type) {
        if (!types.includes(type)) {
          types.push(type);
        }
      }
    });
    return types.sort();
  };

  const filterByState = () => {
    const locations = [];
    data.forEach((project) => {
      const { location } = project;
      if (location.state && typeof location.state === "object") {
        location.state.forEach((state) => {
          if (!locations.includes(state)) {
            locations.push(state);
          }
        });
      } else if (location.state) {
        if (!locations.includes(location.state)) {
          locations.push(location.state);
        }
      }
    });
    return locations.sort();
  };

  const filterTypes = [
    {
      name: "Types",
      filters: filterByType(),
    },
    {
      name: "Switchboards",
      filters: ["≤ 1000A", "≤ 2000A", "≤ 3000A", "≤ 4000A", "≤ 5000A"],
    },
    {
      name: "Location",
      filters: filterByState(),
    },
  ];

  useEffect(() => {
    if (!filteredCoordinates) setCoordinates();
  });

  if (!filteredCoordinates) return null;

  return (
    <div className="projects">
      <div className="projects__list">
        <h3>
          <img loading={"lazy"} src={project} alt="project" width={20} />
          Project References
        </h3>
        {/* <Tabs defaultActiveKey="1">
            {filterTypes.map((type, i) => (
              <TabPane tab={type.name} key={i}>
                <ProjectList
                  filter={type.filters}
                  displayActiveCoordinate={(e) => displayActiveCoordinate(e)}
                />
              </TabPane>
            ))}
          </Tabs> */}
        <ProjectList
          filter={filterTypes[0].filters}
          displayActiveCoordinate={(e) => displayActiveCoordinate(e)}
        />
      </div>
      <div className="projects__map">
        {filteredCoordinates ? (
          <ProjectMap coordinates={filteredCoordinates} />
        ) : null}
      </div>
    </div>
  );
}

const ProjectList = ({ filter, displayActiveCoordinate }) => {
  const joinList = (arr, separator = ", ") => {
    return typeof arr === "object" ? arr.join(separator) : arr;
  };

  return (
    <div>
      {/* <div className="projects__filter">
        <Fade>
          {filter.map((option, i) => (
            <button key={i}>{option}</button>
          ))}
        </Fade>
      </div> */}
      <Collapse
        onChange={(e) => displayActiveCoordinate(e)}
        expandIconPosition={"right"}
        accordion
        className="projects__accordion"
      >
        {data.map((project, i) => {
          return (
            <Panel header={project.name} key={i}>
              <div className="flex">
                {project.switchboard ? (
                  <div>
                    <div className="label">Switchboard type</div>
                    <div className="desc">
                      <span>
                        {project.switchboard.msb
                          ? `MSB: ${project.switchboard.msb}A `
                          : null}
                      </span>
                      <span>
                        {project.switchboard.mcc
                          ? `MCC: ${project.switchboard.mcc}A `
                          : null}
                      </span>
                      <span>
                        {project.switchboard.ssb
                          ? project.switchboard.msb || project.switchboard.mcc
                            ? `(SSB & DB)`
                            : `SSB & DB`
                          : null}
                      </span>
                    </div>
                  </div>
                ) : null}

                {project.owner ? (
                  <div>
                    <div className="label">Project owner</div>
                    <div className="desc">{joinList(project.owner)}</div>
                  </div>
                ) : null}
              </div>

              <div className="flex">
                {project.location.state ? (
                  <div>
                    <div className="label">Location</div>
                    <div className="desc">
                      <span>
                        {project.location.area
                          ? `${project.location.area}, `
                          : null}
                      </span>
                      <span>{joinList(project.location.state)}</span>
                    </div>
                  </div>
                ) : null}
                {project.year ? (
                  <div>
                    <div className="label">Manufacturing year</div>
                    <div className="desc">{joinList(project.year, " - ")}</div>
                  </div>
                ) : null}
              </div>

              <div className="flex">
                {project.type ? (
                  <div>
                    <div className="label">Project type</div>
                    <div className="desc">
                      <span>{project.type ?? null}</span>
                    </div>
                  </div>
                ) : null}
                {project.consultant ? (
                  <div>
                    <div className="label">Consultant</div>
                    <div className="desc">{joinList(project.consultant)}</div>
                  </div>
                ) : null}
              </div>
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

const ProjectMap = ({ coordinates }) => {
  return (
    <Map
      zoom={[6]}
      center={[102.2529671, 3.50959238581376]}
      // eslint-disable-next-line
      style="mapbox://styles/mapbox/dark-v10"
    >
      {coordinates.map((marker, i) => {
        return (
          <Marker
            key={i}
            coordinates={[marker.lng, marker.lat]}
            anchor="bottom"
            radius
          >
            <Fade delay={(i + 1) * 50}>
              <div className={`block block--${marker.type}`}>
                <div className="dot"></div>
              </div>
            </Fade>
          </Marker>
        );
      })}
    </Map>
  );
};
