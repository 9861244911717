import React, { useEffect, useState } from "react";
import ReactResizeDetector from "react-resize-detector";
import Spinner from "./components/Spinner/Spinner";
import { sections } from "./assets/data/sections";
import Navbar from "./components/Navbar/Navbar";
import Wrapper from "./components/Wrapper/Wrapper";
import "./App.scss";
import { Fade } from "react-reveal";

const mobileWidth = 800;

function App() {
  const [content, setContent] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [isMobileView, setMobileView] = useState(
    window.innerWidth > mobileWidth
  );

  const setViewport = ({ weight }) => {
    // Set viewport based on device width for mobile view
    if (weight > 800) {
      if (isMobileView) setMobileView(false);
    } else if (weight <= 800) {
      if (!isMobileView) setMobileView(true);
    }
  };

  useEffect(() => {
    async function setSectionRefs() {
      if (!content) {
        // Create reference to each component in section on mount
        const content = sections.map((section) => {
          return {
            ...section,
            ref: React.createRef(),
          };
        });

        await setContent(content);

        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    }

    setSectionRefs();
  }, [content, setContent, setLoading]);

  return (
    <ReactResizeDetector
      id="root"
      onResize={(weight, height) => setViewport({ weight, height })}
    >
      {!isLoading ? (
        <>
          <Navbar content={content} />
          <Fade duration={1000}>
            {content &&
              content.map((section, i) => (
                <div key={i} id={section.href}>
                  <Wrapper
                    innerRef={section.ref}
                    backgroundMode={section.mode}
                    component={section._component}
                    isWrapped={section.isWrapped}
                  />
                </div>
              ))}
          </Fade>
        </>
      ) : (
        <Fade opposite>
          <Spinner />
        </Fade>
      )}
    </ReactResizeDetector>
  );
}

export default App;
