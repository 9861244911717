import React from "react";
import whatsapp from "../../assets/svg/footer/whatsapp.svg";
import email from "../../assets/svg/footer/email.svg";
import waze from "../../assets/svg/footer/waze.svg";
import maps from "../../assets/svg/footer/google-maps.svg";
import { Copy } from "./../../helpers/copy";
import "./Contact.scss";

export default function Contact({ content }) {
  return (
    <div className="contact">
      <div className="contact__section">
        <h3>Get In Touch</h3>
        <div className="contact__section-1">
          <div className="director">
            <h4>
              Yap Weng Yew <span>(Project Director)</span>
            </h4>
            <div>
              <a href="tel:+6019-260 9935">
                <p>+6019-260 9935</p>
              </a>
              <a target="_blank" rel="noreferrer" href="https://wa.link/v7eusd">
                <img
                  className="copy"
                  src={whatsapp}
                  alt="whatsapp"
                  height={20}
                />
              </a>
              <Copy data={"+6019-260 9935"} />
            </div>

            <div>
              <a href="mailto:yapwy.yl@gmail.com">
                <p>
                  yapwy.yl@gmail.com
                  <img className="copy" src={email} alt="email" height={20} />
                </p>
              </a>
              <Copy data={"yapwy.yl@gmail.com"} />
            </div>
          </div>

          <div className="office">
            <h4>Office Handles</h4>
            <div>
              <a href="tel:+603-8211 2935">
                <p>+603-8211 2935</p>
              </a>
              <Copy data={"+603-8211 2935"} />
            </div>
            <div>
              <a href="tel:+6011-2804 3966">
                <p>+6011-2804 3966</p>
              </a>
              <Copy data={"+6011-2804 3966"} />
            </div>
            <div>
              <a href="mailto:ylswitchgear@gmail.com">
                <p>
                  ylswitchgear@gmail.com
                  <img className="copy" src={email} alt="email" height={20} />
                </p>
              </a>
              <Copy data={"ylswitchgear@gmail.com"} />
            </div>
          </div>
        </div>
      </div>

      <div className="contact__section">
        <div className="contact__section-2">
          <h3>Address</h3>
          <div>
            No. 10, Jalan Industri Kidamai 2/1, Taman Industri Kidamai 2, Bukit
            Angkat, 43000 Kajang, Selangor
            <div className="address-icons">
              <Copy
                data={
                  "No. 10, Jalan Industri Kidamai 2/1, Taman Industri Kidamai 2, Bukit Angkat, 43000 Kajang, Selangor"
                }
              />
              <a
                target="_blank"
                rel="noreferrer"
                href="https://waze.com/ul/hw2835dquh"
              >
                <img className="copy" src={waze} alt="waze" height={24} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://maps.app.goo.gl/hbr5Fb4p4xa28nAX9"
              >
                <img className="copy" src={maps} alt="maps" height={24} />
              </a>
            </div>
          </div>
        </div>
        <div className="contact__section-2">
          <h3>Opening hours</h3>
          <div>Monday - Friday: 8AM - 5PM</div>
          <div>Saturday: 8AM - 1PM</div>
        </div>
        <div className="contact__section-2">
          <h3>SST ID No.</h3>
          <div>W24-1808-21021598</div>
        </div>
      </div>
    </div>
  );
}
