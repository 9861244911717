import { notification } from "antd";
import copy from "../assets/svg/footer/copy.svg";

export const Copy = ({ data }) => {
  const openNotification = () => {
    navigator.clipboard.writeText(data);
    notification.open({
      message: "Copied successfully!",
      description: data,
      placement: "bottomRight",
    });
  };

  return (
    <img
      className="copy"
      src={copy}
      alt="copy"
      height={16}
      onClick={() => openNotification()}
    />
  );
};
