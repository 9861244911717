// eslint-disable-next-line
export const projects = [
  {
    name: "K. Avenue",
    location: {
      area: "Sungai Besi",
      state: "Kuala Lumpur",
      coordinates: [
        {
          lng: 101.7179,
          lat: 3.0574,
        },
      ]
    },
    switchboard: {
      msb: 3200,
      mcc: null,
      ssb: true,
    },
    owner: "Kewingston",
    consultant: "D&O Konsultant",
    year: 2019,
    type: "High Rise",
  },
  {
    name: "Quartz WM",
    location: {
      area: "Wangsa Maju",
      state: "Kuala Lumpur",
      coordinates: [
        {
          lng: 101.7367,
          lat: 3.2038,
        },
      ]
    },
    switchboard: {
      msb: 3200,
      mcc: null,
      ssb: true,
    },
    owner: "Beverly WM",
    consultant: "BK Associates",
    year: 2018,
    type: "High Rise",
  },
  {
    name: "KL Traders Square (Phase 2)",
    location: {
      area: "Kampung Kuantan",
      state: "Kuala Lumpur",
      coordinates: [
        {
          lng: 101.7031,
          lat: 3.2029,
        },
      ]
    },
    switchboard: {
      msb: 3200,
      mcc: null,
      ssb: true,
    },
    owner: "Radical Range",
    consultant: "CJ Neo & Partner",
    year: 2017,
    type: "High Rise",
  },
  {
    name: "Inwood Residence",
    location: {
      area: "Bukit Kerinchi",
      state: "Kuala Lumpur",
      coordinates: [
        {
          lng: 101.6636,
          lat: 3.1022,
        },
      ]
    },
    switchboard: {
      msb: 1250,
      mcc: null,
      ssb: true,
    },
    owner: "Amona",
    consultant: "JPK & Associates",
    year: 2016,
    type: "High Rise",
  },
  {
    name: "Fortune Perdana",
    location: {
      area: "Kepong",
      state: "Kuala Lumpur",
      coordinates: [
        {
          lng: 101.6436,
          lat: 3.218,
        },
      ]
    },
    switchboard: {
      msb: 1600,
      mcc: null,
      ssb: true,
    },
    owner: "BH Realty",
    consultant: "CJ Neo & Partner",
    year: 2016,
    type: "High Rise",
  },
  {
    name: "Hilton Hotel Puchong",
    location: {
      area: "Puchong",
      state: "Selangor",
      coordinates: [
        {
          lng: 101.6857,
          lat: 3.1354,
        },
      ]
    },
    switchboard: {
      msb: 2500,
      mcc: null,
      ssb: true,
    },
    owner: "Millenium",
    consultant: "Urus Jaya",
    year: 2015,
    type: "High Rise",
  },
  {
    name: "GreenZ @ One South",
    location: {
      area: "Seri Kembangan",
      state: "Selangor",
      coordinates: [
        {
          lng: 101.422175,
          lat: 3.23691,
        },
      ]
    },
    switchboard: {
      msb: 1600,
      mcc: null,
      ssb: true,
    },
    owner: "Hua Yang",
    consultant: "TW Perunding",
    year: 2015,
    type: "High Rise",
  },
  {
    name: "Infiniti 3 Residence",
    location: {
      area: "Wangsa Maju",
      state: "Kuala Lumpur",
      coordinates: [
        {
          lng: 101.7378,
          lat: 3.1943,
        },
      ]
    },
    switchboard: {
      msb: 1600,
      mcc: null,
      ssb: true,
    },
    owner: "Setapak Heights",
    consultant: "BK Associates",
    year: 2015,
    type: "High Rise",
  },
  {
    name: "Regina USJ Condo",
    location: {
      area: "Subang",
      state: "Selangor",
      coordinates: [
        {
          lng: 101.5994,
          lat: 3.0524,
        },
      ]
    },
    switchboard: {
      msb: 3200,
      mcc: null,
      ssb: true,
    },
    owner: "Regina Dev",
    consultant: "East Orient Consult",
    year: 2014,
    type: "High Rise",
  },
  {
    name: "Imperial Residency",
    location: {
      area: "Cheras",
      state: "Selangor",
      coordinates: [
        {
          lng: 101.7589,
          lat: 3.0725,
        },
      ]
    },
    switchboard: {
      msb: 1400,
      mcc: null,
      ssb: true,
    },
    owner: "Beverly",
    consultant: "Alif Bersatu Perunding",
    year: 2014,
    type: "High Rise",
  },
  {
    name: "Arte Condominium Kuchai Lama",
    location: {
      area: "Kuchai Lama",
      state: "Kuala Lumpur",
      coordinates: [
        {
          lng: 101.68756485,
          lat: 3.08817243353,
        },
      ]
    },
    switchboard: {
      msb: 1200,
      mcc: null,
      ssb: true,
    },
    owner: "Nusmetro",
    consultant: "OS Consult",
    year: 2014,
    type: "High Rise",
  },
  {
    name: "Decathlon Sport Equipment Store",
    location: {
      area: null,
      state: "Kuala Lumpur",
      coordinates: [
        {
          lng: 101.6781,
          lat: 3.1089,
        },
      ]
    },
    switchboard: {
      msb: 400,
      mcc: null,
      ssb: true,
    },
    owner: "Decathlon",
    consultant: "ADC Consultants",
    year: 2019,
    type: "Fast Pace",
  },
  {
    name: "Harvey Norman Stores",
    location: {
      area: null,
      state: ["Kuala Lumpur", "Penang", "Johor"],
      coordinates: [
        {
          lng: 101.6869,
          lat: 3.139,
        },
        {
          lng: 100.3327,
          lat: 5.4164,
        },
        {
          lng: 103.7618,
          lat: 1.4854,
        },
      ]
    },
    switchboard: {
      msb: 500,
      mcc: null,
      ssb: true,
    },
    owner: "Harvey Norman",
    consultant: "Perunding CH Chan",
    year: [2013, 2019],
    type: "Fast Pace",
  },
  {
    name: "Sam's Groceria",
    location: {
      area: "Shah Alam",
      state: "Selangor",
      coordinates: [
        {
          lng: 100.3128,
          lat: 5.4581,
        },
      ]
    },
    switchboard: {
      msb: 600,
      mcc: null,
      ssb: true,
    },
    owner: "Sam's Groceria",
    consultant: "NDY (M)",
    year: 2015,
    type: "Fast Pace",
  },
  {
    name: "7 Floors Banglos",
    location: {
      area: "Shah Alam",
      state: "Selangor",
      coordinates: [
        {
          lng: 101.5185,
          lat: 3.0733,
        },
      ]
    },
    switchboard: {
      msb: 600,
      mcc: null,
      ssb: true,
    },
    owner: "Dato (DR) Francis Yeoh Sock Ping",
    consultant: "YTL",
    year: 2013,
    type: "Fast Pace",
  },
  {
    name: "GSC Cinemas @ Paradigm Mall",
    location: {
      area: "Petaling Jaya",
      state: "Selangor",
      coordinates: [
        {
          lng: 101.596,
          lat: 3.1051,
        },
      ]
    },
    switchboard: {
      msb: 1000,
      mcc: null,
      ssb: true,
    },
    owner: "Golden Screen Cinemas",
    consultant: "Perunding Segamat",
    year: 2012,
    type: "Fast Pace",
  },
  ,
  {
    name: "Alliance Bank Office",
    location: {
      area: null,
      state: null,
      coordinates: [
        {
          lng: 0,
          lat: 0,
        },
      ]
    },
    switchboard: {
      msb: null,
      mcc: null,
      ssb: true,
    },
    owner: "Alliance Bank",
    consultant: null,
    year: [2012, 2019],
    type: "Fast Pace",
  },
  ,
  {
    name: "Ambank Office",
    location: {
      area: null,
      state: null,
      coordinates: [
        {
          lng: 0,
          lat: 0,
        },
      ]
    },
    switchboard: {
      msb: null,
      mcc: null,
      ssb: true,
    },
    owner: "Ambank",
    consultant: null,
    year: [2012, 2019],
    type: "Fast Pace",
  },
  ,
  {
    name: "CIMB Bank",
    location: {
      area: null,
      state: null,
      coordinates: [
        {
          lng: 0,
          lat: 0,
        },
      ]
    },
    switchboard: {
      msb: null,
      mcc: null,
      ssb: true,
    },
    owner: "CIMB Bank",
    consultant: null,
    year: [2012, 2019],
    type: "Fast Pace",
  },
  ,
  {
    name: "Maybank Office",
    location: {
      area: null,
      state: null,
      coordinates: [
        {
          lng: 0,
          lat: 0,
        },
      ]
    },
    switchboard: {
      msb: null,
      mcc: null,
      ssb: true,
    },
    owner: "Maybank",
    consultant: null,
    year: [2012, 2019],
    type: "Fast Pace",
  },
  ,
  {
    name: "Islamic Bank Office",
    location: {
      area: null,
      state: null,
      coordinates: [
        {
          lng: 0,
          lat: 0,
        },
      ]
    },
    switchboard: {
      msb: null,
      mcc: null,
      ssb: true,
    },
    owner: "Islamic Bank",
    consultant: null,
    year: [2012, 2019],
    type: "Fast Pace",
  },
  ,
  {
    name: "Public Bank Office",
    location: {
      area: null,
      state: null,
      coordinates: [
        {
          lng: 0,
          lat: 0,
        },
      ]
    },
    switchboard: {
      msb: null,
      mcc: null,
      ssb: true,
    },
    owner: "Public Bank",
    consultant: null,
    year: [2012, 2019],
    type: "Fast Pace",
  },
  ,
  {
    name: "Prudential Insurance Agency Office",
    location: {
      area: null,
      state: null,
      coordinates: [
        {
          lng: 0,
          lat: 0,
        },
      ]
    },
    switchboard: {
      msb: null,
      mcc: null,
      ssb: true,
    },
    owner: "Prudential Takaful",
    consultant: null,
    year: [2012, 2019],
    type: "Fast Pace",
  },
  ,
  {
    name: "AIA Insurance Agency Office",
    location: {
      area: null,
      state: null,
      coordinates: [
        {
          lng: 0,
          lat: 0,
        },
      ]
    },
    switchboard: {
      msb: null,
      mcc: null,
      ssb: true,
    },
    owner: "AIA Berhad",
    consultant: null,
    year: [2012, 2019],
    type: "Fast Pace",
  },
  {
    name: "Sewerage Treatment Plant",
    location: {
      area: "Batu Berendam",
      state: "Melaka",
      coordinates: [
        {
          lng: 102.2559,
          lat: 2.2396,
        },
      ]
    },
    switchboard: {
      msb: 2500,
      mcc: null,
      ssb: true,
    },
    owner: "JPP",
    consultant: null,
    year: 2019,
    type: "Government Project",
  },
  {
    name: "Politeknik Hulu Terengganu",
    location: {
      area: "Hulu Terengganu",
      state: "Terengganu",
      coordinates: [
        {
          lng: 103.0089,
          lat: 5.073,
        },
      ]
    },
    switchboard: {
      msb: 2500,
      mcc: null,
      ssb: true,
    },
    owner: "JPP",
    consultant: null,
    year: 2018,
    type: "Government Project",
  },
  {
    name: "Institut Penyelidikan Perubatan (IMR) KL (Phase 2)",
    location: {
      area: "Jalan Pahang",
      state: "Kuala Lumpur",
      coordinates: [
        {
          lng: 101.6986,
          lat: 3.1699,
        },
      ]
    },
    switchboard: {
      msb: 2500,
      mcc: null,
      ssb: true,
    },
    owner: "JKR Malaysia",
    consultant: null,
    year: 2018,
    type: "Government Project",
  },
  {
    name: "Klinik Kesihatan (Type 3)",
    location: {
      area: null,
      state: ["Selangor", "Terengganu"],
      coordinates: [
        {
          lng: 101.5183,
          lat: 3.0738,
        },
        {
          lng: 103.1324,
          lat: 5.3117,
        },
      ]
    },
    switchboard: {
      msb: 1250,
      mcc: null,
      ssb: true,
    },
    owner: "JKR Malaysia",
    consultant: null,
    year: 2018,
    type: "Government Project",
  },
  {
    name: "Kem Rejimen Sempadan",
    location: {
      area: null,
      state: "Pahang",
      coordinates: [
        {
          lng: 103.3256,
          lat: 3.8126,
        },
      ]
    },
    switchboard: {
      msb: 1200,
      mcc: null,
      ssb: true,
    },
    owner: "Kem Pertahanan Malaysia",
    consultant: null,
    year: 2017,
    type: "Government Project",
  },
  {
    name: "Devan D'Kelana Jaya",
    location: {
      area: "Petaling Jaya",
      state: "Selangor",
      coordinates: [
        {
          lng: 101.5942668,
          lat: 3.0954732,
        },
      ]
    },
    switchboard: {
      msb: 1200,
      mcc: null,
      ssb: true,
    },
    owner: "MBPJ",
    consultant: null,
    year: 2017,
    type: "Government Project",
  },
  {
    name: "Sek Tunas Bakti (L) Jerantut",
    location: {
      area: null,
      state: "Pahang",
      coordinates: [
        {
          lng: 102.37138,
          lat: 3.93924,
        },
      ]
    },
    switchboard: {
      msb: 600,
      mcc: null,
      ssb: true,
    },
    owner: "JKR Malaysia",
    consultant: null,
    year: 2016,
    type: "Government Project",
  },
  {
    name: "Balai Polis @ Taman Bayu Damai",
    location: {
      area: null,
      state: "Johor",
      coordinates: [
        {
          lng: 104.265,
          lat: 1.3903,
        },
      ]
    },
    switchboard: {
      msb: 500,
      mcc: null,
      ssb: true,
    },
    owner: "JKR Malaysia",
    consultant: null,
    year: 2016,
    type: "Government Project",
  },
  {
    name: "Bangunan Kejuruteraan UiTM",
    location: {
      area: "Shah Alam",
      state: "Selangor",
      coordinates: [
        {
          lng: 101.5037,
          lat: 3.0697,
        },
      ]
    },
    switchboard: {
      msb: 2500,
      mcc: null,
      ssb: true,
    },
    owner: "UiTM",
    consultant: null,
    year: 2015,
    type: "Government Project",
  },
  {
    name: "Kompleks Ibu Pejabat Risda Ampang (Phase 2)",
    location: {
      area: "Ampang",
      state: "Kuala Lumpur",
      coordinates: [
        {
          lng: 101.7483,
          lat: 3.1597,
        },
      ]
    },
    switchboard: {
      msb: 2500,
      mcc: null,
      ssb: true,
    },
    owner: "RISDA",
    consultant: null,
    year: 2015,
    type: "Government Project",
  },
  {
    name: "Pejabat JKR Daerah Hulu Terengganu",
    location: {
      area: null,
      state: "Terengganu",
      coordinates: [
        {
          lng: 103.043,
          lat: 5.073,
        },
      ]
    },
    switchboard: {
      msb: 600,
      mcc: null,
      ssb: true,
    },
    owner: "JKR Malaysia",
    consultant: null,
    year: 2012,
    type: "Government Project",
  },
  {
    name: "RAW Water Pumping Station (Expansion Phase 2)",
    location: {
      area: "Ipoh",
      state: "Perak",
      coordinates: [
        {
          lng: 101.0756,
          lat: 4.6025,
        },
      ]
    },
    switchboard: {
      msb: 5000,
      mcc: null,
      ssb: true,
    },
    owner: "Lembaga Air Perak",
    consultant: "Aquarius",
    year: 2019,
    type: "Factory / Plant / Expansion",
  },
  {
    name: "UG Global Latex Factory",
    location: {
      area: "Senawang",
      state: "Negeri Sembilan",
      coordinates: [
        {
          lng: 101.98318085,
          lat: 2.6895968,
        },
      ]
    },
    switchboard: {
      msb: 2000,
      mcc: null,
      ssb: true,
    },
    owner: "UG Global Resources",
    consultant: "CT Perunding",
    year: 2017,
    type: "Factory / Plant / Expansion",
  },
  {
    name: "LIMEKLIN Plant",
    location: {
      area: "Kemaman",
      state: "Terengganu",
      coordinates: [
        {
          lng: 103.3638,
          lat: 4.2338,
        },
      ]
    },
    switchboard: {
      msb: 4000,
      mcc: null,
      ssb: true,
    },
    owner: "Pesona Asli",
    consultant: "Dynamic Eng",
    year: 2016,
    type: "Factory / Plant / Expansion",
  },
  {
    name: "U-Mobile Factory & Office (Phase 1)",
    location: {
      area: "Kepong",
      state: "Kuala Lumpur",
      coordinates: [
        {
          lng: 101.6356,
          lat: 3.214,
        },
      ]
    },
    switchboard: {
      msb: 3000,
      mcc: null,
      ssb: true,
    },
    owner: "UMobile",
    consultant: "Funity Technologies",
    year: 2016,
    type: "Factory / Plant / Expansion",
  },
  {
    name: "U-Mobile Factory & Office (Phase 2)",
    location: {
      area: "Kepong",
      state: "Kuala Lumpur",
      coordinates: [
        {
          lng: 101.6356,
          lat: 3.214,
        },
      ]
    },
    switchboard: {
      msb: 3000,
      mcc: null,
      ssb: true,
    },
    owner: "UMobile",
    consultant: "Funity Technologies",
    year: 2016,
    type: "Factory / Plant / Expansion",
  },
  {
    name: "Bousted Naval Shipyard Package 3B (Expansion)",
    location: {
      area: "Lumut",
      state: "Perak",
      coordinates: [
        {
          lng: 100.6322,
          lat: 4.2363,
        },
      ]
    },
    switchboard: {
      msb: 3000,
      mcc: null,
      ssb: false,
    },
    owner: "Bousted Naval Shipyard",
    consultant: "Sepakat Setia",
    year: 2015,
    type: "Factory / Plant / Expansion",
  },
  {
    name: "Nissan Workshop",
    location: {
      area: "Johor Jaya",
      state: "Johor",
      coordinates: [
        {
          lng: 103.7978,
          lat: 1.5356,
        },
      ]
    },
    switchboard: {
      msb: 800,
      mcc: null,
      ssb: true,
    },
    owner: "Tanahku Holdings",
    consultant: "Associated Group Konsult",
    year: 2014,
    type: "Factory / Plant / Expansion",
  },
  {
    name: "TGAST 2500kVA Sub Station",
    location: {
      area: "Kerteh",
      state: "Terengganu",
      coordinates: [
        {
          lng: 103.443,
          lat: 4.5079,
        },
      ]
    },
    switchboard: {
      msb: 4000,
      mcc: null,
      ssb: false,
    },
    owner: "Samsung Engineering",
    consultant: null,
    year: 2014,
    type: "Factory / Plant / Expansion",
  },
  {
    name: "Omega Pesona Aluminium Factory (Expansion)",
    location: {
      area: "Klang",
      state: "Selangor",
      coordinates: [
        {
          lng: 101.4362,
          lat: 3.0173,
        },
      ]
    },
    switchboard: {
      msb: 3000,
      mcc: null,
      ssb: true,
    },
    owner: "Omega Pesona",
    consultant: "RC Engineering",
    year: 2014,
    type: "Factory / Plant / Expansion",
  },
  {
    name: "NSE Steam Renewable Energy Plant",
    location: {
      area: "Bukit Payong",
      state: "Terengganu",
      coordinates: [
        {
          lng: 103.1075,
          lat: 5.2238,
        },
      ]
    },
    switchboard: {
      msb: 3000,
      mcc: null,
      ssb: false,
    },
    owner: "NSE Energy",
    consultant: "Maju Integrated",
    year: 2013,
    type: "Factory / Plant / Expansion",
  },
  {
    name: "SunEel Hitech Factory (Expansion)",
    location: {
      area: "Klang",
      state: "Selangor",
      coordinates: [
        {
          lng: 101.4456,
          lat: 3.0449,
        },
      ]
    },
    switchboard: {
      msb: 1000,
      mcc: null,
      ssb: true,
    },
    owner: "SunEel Hitech",
    consultant: "Perunding JC YEO",
    year: 2013,
    type: "Factory / Plant / Expansion",
  },
  {
    name: "Sewerage Treatment Plant (Pump MSB)",
    location: {
      area: "Batu Berendam",
      state: "Melaka",
      coordinates: [
        {
          lng: 102.2559,
          lat: 2.2396,
        },
      ]
    },
    switchboard: {
      msb: null,
      mcc: 3000,
      ssb: false,
    },
    owner: "JPP",
    consultant: "MINCON",
    year: 2019,
    type: "MCC Control Panel",
  },
  {
    name: "KTCC Mall (Chiller MSB)",
    location: {
      area: "Muara Selatan",
      state: "Terengganu",
      coordinates: [
        {
          lng: 103.1497,
          lat: 5.3333,
        },
      ]
    },
    switchboard: {
      msb: null,
      mcc: 2000,
      ssb: false,
    },
    owner: "KTCC Mall S/B",
    consultant: "VIMAG",
    year: 2019,
    type: "MCC Control Panel",
  },
  {
    name: "Ibu Pejabat Mara (Chiller MSB)",
    location: {
      area: "Chow Kit",
      state: "Kuala Lumpur",
      coordinates: [
        {
          lng: 101.6952836,
          lat: 3.1576964,
        },
      ]
    },
    switchboard: {
      msb: null,
      mcc: 2000,
      ssb: false,
    },
    owner: "Majlis Amanah Rakyat",
    consultant: "MECIP (M)",
    year: 2016,
    type: "MCC Control Panel",
  },
];
