import Coating from "../svg/services/coating.svg";
import Modification from "../svg/services/modification.svg";
import Turret from "../svg/services/turret.svg";
import Busbar from "../svg/services/busbar.svg";
import Cutting from "../svg/services/cutting.svg";
import Bending from "../svg/services/bending.svg";

import MainSwitchboard from "../svg/products/main-switchboard.jpg";
import MainControlCenterPanel from "../svg/products/main-control-center-panel.jpg";
import AMFPanel from "../svg/products/amf-board.jpg";
import CapacitorBankPanel from "../svg/products/capacitor-panel.jpg";
import FeederPillar from "../svg/products/feeder-pillars.jpg";
import DistributionBoard from "../svg/products/distribution-board.jpg";
import MeterPanel from "../svg/products/meter-panel.jpg";
import SteelSwitchboard from "../svg/products/steel-switchboard.jpg";

export const products = [
  {
    name: "Main Switchboard",
    img: MainSwitchboard,
  },
  {
    name: "Main Control Center Panel",
    img: MainControlCenterPanel,
  },
  {
    name: "AMF Panel",
    img: AMFPanel,
  },
  {
    name: "Capacitor Bank Panel",
    img: MeterPanel,
  },
  {
    name: "Street Lighting Feeder Pillar",
    img: FeederPillar,
  },
  {
    name: "Distribution Board",
    img: DistributionBoard,
  },
  {
    name: "Meter Panel",
    img: CapacitorBankPanel,
  },
  {
    name: "Stainless Steel Switchboard",
    img: SteelSwitchboard,
  },
];

export const services = [
  {
    img: Coating,
    title: "Powder Coating Equipment",
  },
  {
    img: Turret,
    title: "CNC Turret Punching Machine",
  },
  {
    img: Busbar,
    title: "Busbar Bending Machine",
  },
  {
    img: Cutting,
    title: "CNC Metal Cutting Machine",
  },
  {
    img: Modification,
    title: "Onsite Modification",
  },
  {
    img: Bending,
    title: "CNC Bending Machine",
  },
];
