import React from "react";
import { timeline } from "./../../assets/data/timeline";
import { Fade } from "react-reveal";
import "./About.scss";

export default function About({ content }) {
  return (
    <div className="about">
      <div className="info-wrapper">
        <h3>Why Us?</h3>
        <div className="info">
          <div className="info-header">
            ASTA Full Type Tested To IEC 61439-2
          </div>
          <div className="info-desc">
            <div className="info-row">
              <p>- 3200A (415V/690V)</p>
              <p>- 2500A (415V/690V)</p>
            </div>
            <div className="info-row">
              <p>- 1600A (415V/690V)</p>
              <p>- 1250A (415V/690V)</p>
            </div>
          </div>
        </div>
        <div className="info">
          <div className="info-header">Recognized under JKR EMAL List</div>
          <div className="info-desc">
            <div className="info-row">
              <p>- Category III Switchboard Manufacturer</p>
            </div>
            <div className="info-row">
              <p>- Completed with Suruhanjaya Tenaga (ST) Certification</p>
            </div>
          </div>
        </div>
        <div className="info">
          <div className="info-header">Industry Standard-Compliant Designs</div>
          <div className="info-desc">
            <div className="info-row">
              <p>- Comply with IEC 61439-2</p>
            </div>
            <div className="info-row">
              <p>- Modular designs</p>
            </div>
            <div className="info-row">
              <p>- Double door designs</p>
            </div>
          </div>
        </div>
      </div>
      <div className="timeline">
        <h3>Timeline</h3>
        <p>
          At YL Switchgear, we strive to supply quality LV switchboards, in
          compliance to latest IEC standards and provide the best services we
          can offer. Thus, we have accumulated certifications over the years to
          ensure the highest quality.
        </p>
        <div>
          {timeline.map((event, i) => (
            <Timeline event={event} index={i} key={i} delay={(i + 1) * 100} />
          ))}
        </div>
      </div>
    </div>
  );
}

const Timeline = ({ event, index, delay }) => {
  const { year, title, icon } = event;

  return (
    <div className="event">
      <div className="event__dash"></div>
      <div className="event__year">
        {year}
        <span className="event__dot">
          {index === 0 ? <span className="event__vertical"></span> : null}
        </span>
      </div>
      <Fade appear delay={delay * 2}>
        <div className="event__timeline">
          <div className="event__wrapper">
            <div className="event__block">
              {icon ? (
                <div className="event__icon">
                  <img loading={'lazy'} src={icon} alt="icon"/>
                </div>
              ) : null}
              <div>{title}</div>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
};
