import spinner from "../../assets/svg/spinner-bg-2.svg";
import "./Spinner.scss";

export default function Spinner() {
  return (
    <div className="spinner-wrapper">
      <img src={spinner} alt="spinner" height={150} />
    </div>
  );
}
