import React, { useEffect, useState } from "react";
import Wrapper from "../Wrapper/Wrapper";
import TextTransition, { presets } from "react-text-transition";
import logo from "../../assets/svg/yl.svg";
import debounce from "lodash.debounce";
import { Fade } from "react-reveal";
import email from "../../assets/svg/navbar/email.svg";
import phone from "../../assets/svg/navbar/phone.svg";
import hours from "../../assets/svg/navbar/hours.svg";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { determineOffSet } from "./../../helpers/offset";
import { Copy } from "./../../helpers/copy";
import "./Navbar.scss";

export default function Navbar({ content }) {
  const [activeIndex, setIndex] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(null);
  const [showHeader, setHeaderVisibility] = useState(true);
  const headerRef = React.createRef();

  useEffect(() => {
    setTimeout(() => {
      setIndex(Number(!activeIndex));
    }, 3000);

    setTimeout(() => {
      if (!headerHeight && headerRef.current) {
        setHeaderHeight(headerRef.current.offsetHeight);
      }
    }, 10);

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  });

  const onScroll = debounce(() => setHeaderVisibility(window.scrollY === 0));

  return (
    <div className="nav">
      <Wrapper
        backgroundMode={1}
        style={{
          padding: 0,
        }}
        component={
          <div className="nav__stack nav__stack--top">
            <div className="content">
              <img alt="logo" src={logo} width={150} />
            </div>
            <div className="contact">
              <div>
                <img src={phone} alt="phone" />
                <span>Phone: </span>
                <span>+603-92351068</span>
                <Copy data={"+603-92351068"} />
              </div>
              <div>
                <img src={email} alt="email" />
                <span>Email: </span>
                <span>ylswitchgear@gmail.com</span>
                <Copy data={"ylswitchgear@gmail.com"} />
              </div>
              <div>
                <img src={hours} alt="hours" />
                <span>Opening hours:</span>
                <span>8AM-5PM</span>
              </div>
            </div>
          </div>
        }
      />
      <Wrapper
        style={{
          padding: 0,
          backgroundColor: showHeader ? "#001452" : "rgba(#001452, 0.2)",
        }}
        backgroundMode={3}
        component={
          <div className="nav__stack nav__stack--bottom">
            <div className="links">
              {content.map((link, i) => (
                <Fade key={i} delay={(i + 1) * 100}>
                  {window.innerWidth > 600 ? (
                    <div className="content">
                      {typeof link.title === "string" ? (
                        <AnchorLink
                          offset={() => determineOffSet()}
                          href={`#${link.href}`}
                        >
                          {link.title}
                        </AnchorLink>
                      ) : (
                        <AnchorLink
                          offset={() => determineOffSet()}
                          href={`#${link.href}`}
                        >
                          <TextTransition
                            inline
                            text={link.title[activeIndex]}
                            springConfig={presets.stiff}
                          />
                        </AnchorLink>
                      )}
                    </div>
                  ) : (
                    <div className="content">
                      {typeof link.titleXS === "string" ? (
                        <AnchorLink
                          offset={() => determineOffSet()}
                          href={`#${link.href}`}
                        >
                          {link.titleXS}
                        </AnchorLink>
                      ) : (
                        <AnchorLink
                          offset={() => determineOffSet()}
                          href={`#${link.href}`}
                        >
                          <TextTransition
                            inline
                            text={link.titleXS[activeIndex]}
                            springConfig={presets.stiff}
                          />
                        </AnchorLink>
                      )}
                    </div>
                  )}
                </Fade>
              ))}
            </div>
          </div>
        }
      />
      <div className="nav__progress-bar">
        <div className="bar">
          <div className="progress"></div>
        </div>
      </div>
    </div>
  );
}
