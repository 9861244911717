import React, { useEffect, useState } from "react";
import Service from "../../assets/svg/services/service.svg";
import bottom from "../../assets/svg/services/wrapper-bottom.svg";
import top from "../../assets/svg/services/wrapper-top.svg";
import Wrapper from "../Wrapper/Wrapper";
import TextTransition, { presets } from "react-text-transition";
import { products, services } from "../../assets/data/products";
import TrackVisibility from "react-on-screen";
import "./ProductRange.scss";

const Services = () => {
  const [activeIndex, setIndex] = useState(0);

  const switchBoardVariants = ["Quotation", "Fabrication"];

  useEffect(() => {
    setTimeout(() => {
      setIndex(Number(!activeIndex));
    }, 3000);
  }, [activeIndex, setIndex]);

  return (
    <div className="services">
      <div className="services__info">
        <div className="services__wrapper">
          <div className="services__header">
            <img loading={'lazy'} src={Service} alt="Service" />
            <h3 className="title">Services</h3>
          </div>
          <div>
            {"LV Switchboard "}
            <TextTransition
              inline
              text={switchBoardVariants[activeIndex]}
              springConfig={presets.molasses /*gentle*/}
            />
          </div>
          <div>Shoplot Drawing Preparation</div>
          <div>On-Site Switchboard Modification</div>
          <div>Metal Enclosure Fabrication</div>
        </div>
      </div>
      <div className="services__list">
        <div className="services__header">
          <h3 className="title">Facilities</h3>
        </div>
        {services.map((service, i) => (
          <div key={i} className="service">
            <img loading={'lazy'} src={service.img} alt={service.title} />
            <div>{service.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Products = () => {
  return (
    <>
      <h3 className="title">Our Products</h3>
      <div className="product-wrapper">
        {products.map((product, i) => (
          <div key={i} className="product">
            <div className="product-desc">
              <p>{product.name}</p>
            </div>
            <img loading={'lazy'} alt={product.name} src={product.img} />
          </div>
        ))}
      </div>
    </>
  );
};

export default function ProductRange({ content }) {
  return (
    <TrackVisibility partialVisibility>
      {({ isVisible }) => (
        <div className="product-range">
          <img loading={'lazy'} src={top} alt="wrapper-top" className="wrapper-top" />
          <Wrapper
            className="services-skewed"
            style={{ padding: "0 4em", marginBottom: 0 }}
            component={<Services />}
            backgroundMode={4}
          ></Wrapper>
          <img loading={'lazy'} src={bottom} alt="wrapper-bottom" className="wrapper-bottom" />

          <Wrapper component={<Products />} backgroundMode={2}></Wrapper>
        </div>
      )}
    </TrackVisibility>
  );
}
