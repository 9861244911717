import About from "../../components/About/About";
import Home from "../../components/Home/Home";
import Certifications from "../../components/Certifications/Certifications";
import ProductRange from "../../components/ProductRange/ProductRange";
import Projects from "../../components/Projects/Projects";
import Contact from "../../components/Contact/Contact";

export const sections = [
  {
    title: "Home",
    titleXS: "Home",
    href: "home",
    mode: 1,
    isWrapped: false,
    _component: <Home />,
  },
  {
    title: "About",
    titleXS: "About",
    href: "about",
    mode: 2,
    isWrapped: true,
    _component: <About />,
  },
  {
    title: ["Specifications", "Certifications"],
    titleXS: ["Specs.", "Certs."],
    href: "specs",
    mode: 1,
    isWrapped: false,
    _component: <Certifications />,
  },
  {
    title: "Product Range",
    titleXS: "Products",
    href: "products",
    mode: 2,
    isWrapped: false,
    _component: <ProductRange />,
  },
  {
    title: "Project References",
    titleXS: "References",
    href: "references",
    mode: 1,
    isWrapped: true,
    _component: <Projects />,
  },
  {
    title: "Contact",
    titleXS: "Contact",
    href: "contact",
    mode: 3,
    isWrapped: true,
    _component: <Contact />,
  },
];
