import voltage from "../svg/certificates/voltage.svg";
import rating from "../svg/certificates/rating.svg";
import frequency from "../svg/certificates/frequency.svg";
import segregation from "../svg/certificates/segregation.svg";

export const buttonSpecs = [
  {
    title: "Operating Voltage",
    desc: "240VAC/415VAC",
    icon: voltage,
  },
  {
    title: "Current Rating",
    desc: "Maximum 6000A",
    icon: rating,
  },
  {
    title: "Frequency",
    desc: "50Hz or 60Hz",
    icon: frequency,
  },
  {
    title: "Form of Segregation",
    desc: "Form 2B, 3B & 4B",
    icon: segregation,
  },
];

export const tableSpecs = [
  {
    title: "Construction",
    desc:
      "Double door design with fully modular and easy flexibility of interchanging parts",
  },
  {
    title: "Metal Sheet Thickness \n(EG Steel / Stainless Steel)",
    desc:
      "Floor mount (2.3mm-2.5mm U-channel for framework, 1.5/2.0mm for door cover, 1.5/1.2mm for internal separation) & wall mount (1.5/1.2mm for cubicle/door)",
  },
  {
    title: "Finishing Paint Work",
    desc:
      "Epoxy powder coated in gray colour, with a minimum of 40 micron in thickness",
  },
  {
    title: "Busbar System",
    desc:
      "Tinned copper busbars are housed in separated compartments on top of the cubicle with natural ventilation",
  },
  {
    title: "Degree of Protection for Main Switchboard",
    desc: "Standard is IP41 (more on request)",
  },
  {
    title: "Cable Entry",
    desc: "Suitable for top & bottom entry",
  },

  {
    title: "Ambient Temperature",
    desc: "Fully tropicalised up to 40°C",
  },
];
