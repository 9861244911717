import ASTA18855 from "../img/certs/asta-18855.png";
import ASTA18856 from "../img/certs/asta-18856.png";
import ASTA18857 from "../img/certs/asta-18857.png";
import ASTA18858 from "../img/certs/asta-18858.png";
import EMAL from "../img/certs/sijil-emal.png";
import SuruhanjayaTenaga from "../img/certs/sijil-suruhanjaya-tenaga.png";
import SIRIM1573 from "../img/certs/sirim-1573.png";
import SIRIM1288 from "../img/certs/sirim-1288.png";

export const certifications = [
  {
    title: ["EMAL Certification", "(JKR)"],
    src: EMAL,
    type: "local",
  },
  {
    title: ["Suruhanjaya Tenaga", "Certification (ST)"],
    src: SuruhanjayaTenaga,
    type: "local",
  },
  {
    title: ["SIRIM Calibration", "(Insulation Tester)"],
    src: SIRIM1573,
    type: "local",
  },
  {
    title: ["SIRIM Calibration", "(Withstanding Voltage Tester)"],
    src: SIRIM1288,
    type: "local",
  },
  {
    title: ["3200A ASTA Certification", "(No. 18855)"],
    src: ASTA18855,
    type: "international",
  },
  {
    title: ["2500A ASTA Certification", "(No. 18856)"],
    src: ASTA18856,
    type: "international",
  },
  {
    title: ["1600A ASTA Certification", "(No. 18857)"],
    src: ASTA18857,
    type: "international",
  },
  {
    title: ["1250A ASTA Certification", "(No. 18858)"],
    src: ASTA18858,
    type: "international",
  },
];
