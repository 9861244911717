import "./Wrapper.scss";

export default function Wrapper({
  backgroundMode,
  component,
  style,
  className,
  isWrapped = true,
  innerRef,
}) {
  return (
    <>
      {isWrapped ? (
        <div
          ref={innerRef}
          id="wrapper"
          className={`bg-${backgroundMode} ${className ?? ""}`}
          style={style}
        >
          <div className={`limiter`}>{component}</div>
        </div>
      ) : (
        <div ref={innerRef}>{component}</div>
      )}
    </>
  );
}
