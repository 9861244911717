import React, { useState } from "react";
import { certifications } from "../../assets/data/certifications";
import { Radio } from "antd";
import { buttonSpecs, tableSpecs } from "../../assets/data/specifications";
import Wrapper from "../Wrapper/Wrapper";
import { Fade } from "react-reveal";
import "./Certifications.scss";

export default function CertificationsWrapper({ content }) {
  return (
    <>
      <Wrapper component={<Specifications />} backgroundMode={1} />
      <Wrapper component={<Certifications />} backgroundMode={2} />
    </>
  );
}

const Certifications = () => {
  const [certs, setCerts] = useState(certifications);
  const [displayCerts, setDisplayCerts] = useState(true);

  const filterCerts = (e) => {
    setDisplayCerts(false);
    e.target.value === "all"
      ? setCerts(certifications)
      : setCerts(certifications.filter((cert) => cert.type === e.target.value));

    setInterval(() => {
      setDisplayCerts(true);
    }, 0);
  };

  return (
    <div>
      <div className="certifications__header">
        <div className="certifications__desc">
          <div className="certifications__title">
            <h3>Certifications</h3>
          </div>
          <p>
            To ensure the highest quality of our production, we have obtained a
            multitude of certifications over the years of our operations.
          </p>
        </div>
        <div className="certifications__options">
          <Radio.Group defaultValue="all">
            <Radio.Button value="all" onChange={(e) => filterCerts(e)}>
              All
            </Radio.Button>
            <Radio.Button value="local" onChange={(e) => filterCerts(e)}>
              Local
            </Radio.Button>
            <Radio.Button
              value="international"
              onChange={(e) => filterCerts(e)}
            >
              International
            </Radio.Button>
          </Radio.Group>
        </div>
      </div>
      <div className="certifications__wrapper">
        {displayCerts &&
          certs.map((cert, i) => {
            return (
              <Fade key={i} cascade delay={(i + 1) * 100}>
                <div className="certifications__container">
                  <div className="certifications__cert">
                    <img loading={"lazy"} alt="Cert" src={cert.src} />
                    <div className={`title title--${cert.type}`}>
                      <div className="title-wrapper">
                        {cert.title.map((title, i) => (
                          <span key={i}>{title}</span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </Fade>
            );
          })}
      </div>
    </div>
  );
};

const Specifications = () => {
  return (
    <div className="specifications__wrapper">
      <div className="specifications__header">
        <div className="specifications__desc">
          <div className="specifications__title">
            <h3>Specifications</h3>
          </div>
        </div>
      </div>
      <div className="specifications__types">
        {buttonSpecs.map((spec, i) => (
          <Fade key={i} top delay={(i + 1) * 100}>
            <div className="specifications__type">
              <div className="icon">
                <img loading={"lazy"} src={spec.icon} alt="specs-icon" />
              </div>
              <div>
                <div className="header">{spec.title}</div>
                <div className="desc">{spec.desc}</div>
              </div>
            </div>
          </Fade>
        ))}
      </div>
      <p>
        To ensure the highest quality of our production, we have obtained a
        multitude of certifications over the years of our operations.
      </p>
      <div className="specifications__table">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {tableSpecs.map((spec, i) => (
              <tr key={i}>
                <td>{spec.title}</td>
                <td>{spec.desc}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
