import idea from "../svg/about/idea.svg";
import medal from "../svg/about/medal.svg";
import factory from "../svg/about/factory.svg";

export const timeline = [
  {
    year: "2010",
    title: "Established by founder, Yap Weoi Kee",
    icon: idea,
  },
  {
    year: "2012",
    title: "JKR KAT III EMAL Certification",
    icon: medal,
  },
  {
    year: "2013",
    title: "ASTA Certificates of Verification Test",
    icon: medal,
  },
  {
    year: "2018",
    title: "Relocated operational factory to Bukit Angkat",
    icon: factory,
  },
  {
    year: new Date().getFullYear(),
    title: "",
  },
];
